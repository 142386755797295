module.exports = {
  BASE_URL: "https://api.giaohangtietkiemvn.site/api/v1",
  SOCKET_SERVER_URL: "https://api.giaohangtietkiemvn.site/",
  // BASE_URL: "http://localhost:8089/api/v1",
  // SOCKET_SERVER_URL: "http://localhost:8089",
  MESSAGE_TYPE: {
    TEXT: "text",
    URL: "url",
  },
  ROLE: {
    USER: "USER",
    ROOT: "ROOT",
    ADMIN: "ADMIN",
  },
};
